<!-- <ejs-dialog id="depositForm" #depositForm header="Add Deposit" [showCloseIcon]="true" [isModal]="true"
  [closeOnEscape]="true" [visible]="visibility" (close)="depositForm.hide()" (beforeOpen)="beforeOpening($event)"
  width="1000px"> -->
  <div class="row">
    <div class="col-4">
      <ejs-datepicker [(ngModel)]="formData.DepositDate" placeholder="Date Recieved"
        floatLabelType="Auto"></ejs-datepicker>
    </div>
    <div class="col-4">
      <ejs-numerictextbox min="0" [(ngModel)]="formData.DepositAmount" placeholder="Deposit Amount"
        floatLabelType="Auto"></ejs-numerictextbox>
    </div>
    <div class="col-4">
      <ejs-dropdownlist [dataSource]="listPandL" [query]="pAndLQuery" [fields]="pAndLFields"
        [(ngModel)]="formData.DepositToAccount" placeholder="Deposit to Account"
        floatLabelType="Auto"></ejs-dropdownlist>
    </div>
    <!-- <div class="form-item">
        Lock Deposit: <ejs-switch onLabel="Yes" offLabel="No" [(ngModel)]="formData.lockDeposit"></ejs-switch>
      </div> -->
    <div class="col-12">
      <ejs-textbox multiline="true" [(ngModel)]="formData.Notes" placeholder="Notes"
        floatLabelType="Auto"></ejs-textbox>
    </div>
    <div class="form-item">
      Final Check: <ejs-switch onLabel="Yes" offLabel="No" [(ngModel)]="formData.FinalCheck"></ejs-switch>
    </div>
    <!-- <div class="form-item">
        Total Amount Due: <ejs-textbox [(ngModel)]="formData.totalAmountDue"></ejs-textbox>
      </div>
      <div class="form-item">
      Amount of Payment: <ejs-textbox [(ngModel)]="formData.amountOfPayment"></ejs-textbox>
    </div> -->
    <div class="col-10"> </div>
    <div class="col-2 form-submit">
      <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true"
        (click)="onSubmit()">Submit</button>
    </div>
  </div>
<!-- </ejs-dialog> -->