// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';

// 3rd Party
import { DataManager, Predicate, Query, ReturnOption } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Invoice, InvoiceRow, InvoicePayment, ProcedureCode, Provider, ListPaymentMethod, ListPaymentStatus } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { Spacer } from '../../../ui/spacer/spacer.component';
import { FormRequiredField } from '@forms/ui/required-field/required-field.component';

@Component({
  selector: 'add-new-invoice-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SwitchModule,
    TextBoxModule,
    DropDownListModule,
    DatePickerModule,
    NumericTextBoxModule,
    ReactiveFormsModule,
    Spacer,
    FormRequiredField
  ],
  templateUrl: './add-new-invoice-form.component.html',
  styleUrl: './add-new-invoice-form.component.scss'
})
export class AddNewInvoiceForm {

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private fb: FormBuilder
  ) { }

  // Inputs
  @Input() showSubmitButton?: boolean = false;

  // Data setup
  providerList: Provider[] = [];
  paymentMethods: ListPaymentMethod[];
  paymentStatuses: ListPaymentStatus[];
  procedureCodes: ProcedureCode[] = [];

  // Forms
  invoiceForm: FormGroup = new FormGroup({
    ProviderInvoiceNumber: new FormControl<Invoice['ProviderInvoiceNumber'] | null>(null, Validators.required),
    InternalInvoiceNumber: new FormControl<Invoice['InternalInvoiceNumber'] | null>(null),
    InvoiceDate: new FormControl<Invoice['InvoiceDate'] | null>(null, Validators.required),
    LockInvoice: new FormControl<Invoice['LockInvoice'] | null>(null),
    Notes: new FormControl<Invoice['Notes'] | null>(null),
    SplitInvoice: new FormControl<Invoice['SplitInvoice'] | null>(null),
    SplitInvoiceId: new FormControl<Invoice['SplitInvoiceId'] | null>(null),
    InvoiceTransferred: new FormControl<Invoice['InvoiceTransferred'] | null>(null),
    CaseFileId: new FormControl<Invoice['CaseFileId'] | null>(null),
    ProviderId: new FormControl<Invoice['ProviderId'] | null>(null, Validators.required),
    Provider: new FormGroup({
      Id: new FormControl<Provider['Id'] | null>(null, Validators.required),
      InNetwork: new FormControl<Provider['InNetwork'] | null>(null),
    }),
    InvoiceRows: new FormArray([]),
    InvoicePayments: new FormArray([]),
  });
  get invoiceRows(): FormArray { return this.invoiceForm.get('InvoiceRows') as FormArray; }
  get invoicePayments(): FormArray { return this.invoiceForm.get('InvoicePayments') as FormArray; }
  get hasInvoiceRows(): boolean { return this.invoiceRows.length > 0; }
  get hasInvoicePayments(): boolean { return this.invoicePayments.length > 0; }
  
  ngOnInit() {
    // hold please...
  }

  async onProviderDDCreated(args: any) {
    const query = new Query().expand('FeeSchedule($expand=XrefFeeScheduleProcedureCodes)').select('Id,Name,InNetwork,Active').where('Active', 'equal', true);
    await this.api.getOdata(APIEndpoints.Providers).executeQuery(query).then((res: any) => {
      this.providerList = res.result as Provider[];
    })
  }

  onProviderDDChange(args: any) {
    const provider = args.itemData as Provider;
    this.getProcedureCodes(provider);
  }

  async getProcedureCodes(provider: Provider) {
    const availableCodes = provider?.FeeSchedule?.XrefFeeScheduleProcedureCodes?.map(xref => xref.ProcedureCodeId).filter((code): code is number => code !== undefined) ?? [];
    const predicate = Predicate.or(availableCodes.map(code => new Predicate('Id', 'equal', code)));
    const query = new Query().select('Id,ProcedureCodeName,Description').expand('ModalityType($select=Id,Description)').where(predicate);
    const res = await this.api.getOdata(APIEndpoints.ProcedureCodes).executeQuery(query) as ReturnOption;
    this.procedureCodes = res.result as ProcedureCode[];
  }

  addInvoiceRow() {
    const newRow = new FormGroup({
      DateOfService: new FormControl<InvoiceRow['DateOfService'] | null>(null),
      ProcedureCodeId: new FormControl<InvoiceRow['ProcedureCodeId'] | null>(null),
      AmountBilled: new FormControl<InvoiceRow['AmountBilled'] | null>(null),
      ReimbursementRate: new FormControl<InvoiceRow['ReimbursementRate'] | null>(null),
      TotalDueProvider: new FormControl<InvoiceRow['TotalDueProvider'] | null>(null),
      SettlementValue: new FormControl<InvoiceRow['SettlementValue'] | null>(null)
    });
    this.invoiceRows.push(newRow);
  }

  addInvoicePayment() {
    const newRow = new FormGroup({
      DatePaid: new FormControl<InvoicePayment['DatePaid'] | null>(null),
      BalanceDue: new FormControl<InvoicePayment['BalanceDue'] | null>(null),
      AmountPaid: new FormControl<InvoicePayment['AmountPaid'] | null>(null),
      PaymentMethod: new FormControl<InvoicePayment['PaymentMethod'] | null>(null),
      PaymentStatus: new FormControl<InvoicePayment['PaymentStatus'] | null>(null),
    });
    this.invoicePayments.push(newRow);
  }

  deleteInvoiceRow(index: number ) { 
    this.invoiceRows.removeAt(index); 
  };

  deleteInvoicePayment(index: number) {
    this.invoicePayments.removeAt(index);
  }

  formatProcedureCodes(procedureCodes: ProcedureCode[]): string[] {
    return procedureCodes.map((procedureCode) => (procedureCode.ProcedureCodeName = `${procedureCode.ProcedureCodeName} - ${procedureCode.Description}`));
  }

  onSubmit(): void {
    console.log(this.invoiceForm.value);
  }

  logRow(row: any, index: number) {
    console.log(row.value, row, index);
  }
}
