// Angular
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { Dialog, DialogModule } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'add-deposit-form',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    SwitchModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    DatePickerAllModule,
    DialogModule
  ],
  templateUrl: './add-deposit.component.html',
  styleUrl: './add-deposit.component.scss',
})
export class AddDepositForm {
  constructor(private api: ApiService, private toast: ToastMessageService) { }

  @Input() caseFileId: Number;
  visibility = false;
  @Output() submit = new EventEmitter<any>();
  @ViewChild('depositForm') public depositForm: Dialog

  public listPandL = this.api.getOdata(APIEndpoints.PandLs);
  public pAndLQuery = new Query().select(["Id", "Description"]);
  public pAndLFields = {
    text: "Description",
    value: "Id",
  };
  public createdBy = JSON.parse(localStorage.getItem('userId') || "0");

  // Form State
  public formData: Deposit = {
    DepositDate: '',
    DepositAmount: null,
    DepositToAccount: 0,
    Notes: '',
    FinalCheck: false,
    CreatedBy: this.createdBy,
  };

  // Iterate through each key in the object, Format it for backend
  formatDates(formObj: any) {
    for (const key in formObj) {
      if (formObj.hasOwnProperty(key)) {
        const value = formObj[key];
        if (value instanceof Date) {
          formObj[key] = value.toISOString().slice(0, 10); // YYYY-MM-DD format
        }
      }
    }
    return formObj;
  }

  beforeOpening = (args: any) => args.maxHeight = '85vh';

  showModal = () => { this.visibility = true; this.depositForm.show() }
  hideModal = () => { this.visibility = false; this.depositForm.hide() }

  onSubmit() {
    this.api.basicPost(
      APIEndpoints.Deposits,
      this.formatDates({ CaseFileId: this.caseFileId, ...this.formData })
    ).then((response) => {
      // close form and refresh grid
      this.submit.emit();
      if (response?.status === 400) {
        this.toast.showError("There was a problem submitting the form")
      }
    })
  }
}
