<div id="add-new-invoice-form">

    <form id="invoice-form" [formGroup]="invoiceForm" class="row">

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="Provider">Provider</label>
            <ejs-dropdownlist #providerDD 
                allowFiltering="true"
                formControlName="ProviderId"
                placeholder="Select Provider" 
                [fields]="{ id: 'Id', text: 'Name' }"
                [dataSource]="providerList"
                (created)="onProviderDDCreated($event)"
                (change)="onProviderDDChange($event)" />
            <form-required-field [form]="invoiceForm" [field]="'ProviderId'" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="ProviderInvoiceNumber">Provider Invoice Number</label>
            <ejs-textbox formControlName="ProviderInvoiceNumber" />
            <form-required-field [form]="invoiceForm" [field]="'ProviderInvoiceNumber'" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="InternalInvoiceNumber">Internal Invoice Number</label>
            <ejs-textbox formControlName="InternalInvoiceNumber" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4" formGroupName="Provider">
            <label for="InNetwork">Provider In Network</label>
            <ejs-switch formControlName="InNetwork" onLabel="Yes" offLabel="No" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="InvoiceDate">Invoice Date</label>
            <ejs-datepicker formControlName="InvoiceDate" />
            <form-required-field [form]="invoiceForm" [field]="'InvoiceDate'" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="LockInvoice">Lock Invoice</label>
            <ejs-switch formControlName="LockInvoice" onLabel="Yes" offLabel="No" />
        </div>

        <div class="form-group col-12 col-sm-6 col-lg-4">
            <label for="Notes">Notes</label>
            <ejs-textbox formControlName="Notes" [multiline]="true" />
        </div>

        @if (!hasInvoiceRows) {

            <button ejs-button (click)="addInvoiceRow()" type="button" class="e-btn e-btn-tertiary">Add Invoice Rows</button>

        } @else {

            <div class="form-group col-12 d-flex flex-column" formArrayName="InvoiceRows">

                @for(row of invoiceRows.controls; let i = $index; track i) {

                    @if (i > 0) { <spacer-element /> }

                    <form class="row sub-form" [formGroupName]="i">
                        <div class="header col-12 col-sm-3 col-lg-1">
                            <h4>Row: {{i + 1}}</h4>
                        </div>

                        <div class="col-12 col-sm-8 col-lg-10">
                            <div class="row">
                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="DateOfService">Date of Service</label>
                                    <ejs-datepicker formControlName="DateOfService" />
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="ProcedureCode">Procedure Code</label>
                                    <ejs-dropdownlist formControlName="ProcedureCodeId" allowFiltering="true" placeholder="Select Code Based On Provider"
                                        [fields]="{ value: 'Id', text: 'Description', Group: 'ModalityType' }" 
                                        [dataSource]="procedureCodes" />
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="AmountBilled">Amount Billed ($)</label>
                                    <ejs-numerictextbox formControlName="AmountBilled" min="0" />
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="ReimbursementRate">Reimbursement Rate (%)</label>
                                    <ejs-numerictextbox formControlName="ReimbursementRate" min="0" />
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="TotalDueProvider">Total Due Provider ($)</label>
                                    <ejs-numerictextbox formControlName="TotalDueProvider" min="0" />
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="SettlementValue">Settlement Value ($)</label>
                                    <ejs-numerictextbox formControlName="SettlementValue" min="0" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-1">
                            <div class="row">
                                <div class="col-6 action">
                                    <button ejs-button (click)="deleteInvoiceRow(i)" iconCss="e-icons e-close"></button>
                                </div>
                                <div class="col-6 action">
                                    <button ejs-button (click)="addInvoiceRow()" iconCss="e-icons e-plus"></button>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div> 
        }

        @if (!hasInvoicePayments) {

            <button ejs-button (click)="addInvoicePayment()" type="button" class="e-btn e-btn-tertiary">Add Invoice Payments</button>

        } @else {

            <spacer-element />

            <div class="form-group col-12 d-flex flex-column" formArrayName="InvoicePayments">
                            
                @for(row of invoicePayments.controls; let i = $index; track i) {
                    
                    @if (i > 0) { <spacer-element /> }

                    <div class="row sub-form" [formGroupName]="i">
                        <div class="header col-12 col-sm-3 col-lg-1">
                            <h4>Payment: {{i + 1}}</h4>
                        </div>

                        <div class="col-12 col-sm-8 col-lg-10">
                            <div class="row">
                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="DatePaid">Date Paid</label>
                                    <ejs-datepicker formControlName="DatePaid" />
                                </div>
            
                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="BalanceDue">Balance Due ($)</label>
                                    <ejs-numerictextbox formControlName="BalanceDue" />
                                </div>
            
                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="AmountPaid">Amount Paid ($)</label>
                                    <ejs-numerictextbox formControlName="AmountPaid" />
                                </div>
            
                                <div class="form-group col-12 col-sm-6 col-md-4 entry   ">
                                    <label for="PaymentMethod">Payment Method</label>
                                    <ejs-dropdownlist formControlName="PaymentMethod" allowFiltering="true"
                                        [fields]="{value: 'Id', text: 'Description'}" 
                                        [dataSource]="paymentMethods">
                                    </ejs-dropdownlist>
                                </div>
            
                                <div class="form-group col-12 col-sm-6 col-md-4 entry">
                                    <label for="PaymentStatus">Payment Status</label>   
                                    <ejs-dropdownlist formControlName="PaymentStatus" allowFiltering="true"
                                        [fields]="{value: 'Id', text: 'Description'}" 
                                        [dataSource]="paymentStatuses">
                                    </ejs-dropdownlist>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-1">
                            <div class="row">
                                <div class="col-6 action">
                                    <button ejs-button (click)="deleteInvoicePayment(i)" iconCss="e-icons e-close"></button>
                                </div>
                                <div class="col-6 action">
                                    <button ejs-button (click)="addInvoicePayment()" iconCss="e-icons e-plus"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            
            </div>

        }
        
        @if (showSubmitButton) {
            <div class="form-group from-submit">
                <button ejs-button [isPrimary]="true" type="submit" (click)="onSubmit()">Submit</button>
            </div>
        }
    </form>
</div>